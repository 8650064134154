<template>
    <div class="tv-radio__slider" v-if="slideShow.length > 0">
        <div class="swiper mySwiper">
            <swiper
              class="swiper-wrapper light-theme"
              :modules="modules"
              :slidesPerView="swiperOptions.slidesPerView"
              :effect="'coverflow'"
              :centeredSlides="true"
              :autoHeight="swiperOptions.autoHeight"
              :loop="true"
              :autoplay="autoplay"
              :breakpoints="swiperOptions.breakpoints"
              :coverflowEffect="coverflowEffect"
            >
                <swiper-slide class="swiper-slide" v-for="list, index in slideShow">
                    <img v-if="list.get_preview" :src="list.get_preview" class="tv-radio__slider_img">
                    <img v-else src="@/assets/img/tv-radio.jpg" class="tv-radio__slider_img">
                    <div class="tv-radio__slider_tag">
                        <p v-for="item in list.tag" 
                            :class="{'tv-radio__slider_gray': item.color == 0, 
                            'tv-radio__slider_blue': item.color == 1}">{{ item.name }}</p>
                    </div>
                    <div class="tv-radio__slider_info">
                        <p class="tv-radio__slider_name">{{ list.name }}</p>
                        <button class="btn__wht" @click="GoTo(list.link)"><p>Смотреть</p></button>
                    </div>                
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>
<style>

</style>
<script>
  import { ref } from 'vue';
  import axios from 'axios'
  import moment from 'moment';
  import SwiperCore, { Navigation, Autoplay, EffectCoverflow } from "swiper";
  import { Swiper, SwiperSlide } from 'swiper/vue';
  SwiperCore.use([Autoplay, EffectCoverflow]);
  import 'swiper/css';
  import "swiper/css/effect-coverflow";

  export default {
    name: 'TvCatalog',
    data() {
      return {
        autoplay: {
          delay: 5000,
        },
        swiperOptions: {
            effect: "coverflow",
            centeredSlides: true,
            slidesPerView: 'auto',
            autoHeight: true,
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
            coverflowEffect: {
                rotate: 70,
                stretch: 0,
                depth: 1100,
                modifier: 1,
                slideShadows: false,
            },
            breakpoints: {
                600: {
                    spaceBetween: 150,
                    coverflowEffect: {
                        rotate: 70,
                        stretch: 0,
                        depth: 1400,
                        modifier: 1,
                        slideShadows: false,
                    },
                },
            },
       },
       cat_lists: [],
      }
    },
    components: {
      Swiper,
      SwiperSlide,
    },
    props: {
        slideShow: {
            type: Array,
            default: [],
        }
    },
    methods: {   
        GoTo(redirect) {
            this.$router.push(redirect)
        },
        CorDate(date) {
              moment.locale('ru');
              const datasort = moment(String(date)).format('DD MMMM YYYY')
              return datasort
        },
    },
    setup() {
       const prev = ref(null);
       const next = ref(null);
      return {
        modules: [EffectCoverflow],
        prev,
        next,
      };
    },
    created() {

    }
  };
</script>