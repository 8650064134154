<template>
    <div>
        
        <div class="">
            <swiper
              :modules="modules"
              :breakpoints="swiperOptions.breakpoints"
              :slidesPerView="3.6"
              :navigation="{
                  prevEl: prev,
                  nextEl: next,
              }"
              @swiper="onSwiper"
              class="tv__bar"
            >
                <div ref="prev" class="slick-prev slick-arrow">prev</div>
                    <swiper-slide
                        v-for="list, index in tv"
                        class="tv__bar_item" 
                        :class="{'active': currentId == list.id, 'tv__bar_dis': 3 > index}"
                        @click="SetCurrent(list)"
                    >
                        <p class="tv__bar_day">
                            {{ DateToday(list.date_to_start) }}
                        </p>
                        <p class="tv__bar_date">
                            {{ CorDate(list.date_to_start) }}
                        </p>
                    </swiper-slide>
                <div ref="next" class="slick-next slick-arrow">next</div>
            </swiper>
        </div>
    </div>
</template>
<style>

</style>
<script>
  import { ref } from 'vue';
  import axios from 'axios'
  import moment from 'moment';
  import SwiperCore, { Navigation, Autoplay} from "swiper";
  import { Swiper, SwiperSlide } from 'swiper/vue';
  SwiperCore.use(Autoplay);
  import 'swiper/css';
  import 'swiper/css/navigation';

  export default {
    name: 'MainCategory',
    data() {
      return {
        autoplay: {
          delay: 5000,
        },
        swiperOptions: {
            breakpoints: {       
                700: {       
                   slidesPerView: 7,
                   spaceBetween: 15    
                },                                        
            }   
       },
       cat_lists: [],
      }
    },
    components: {
      Swiper,
      SwiperSlide,
    },
    props: {
        tv: {
            type: Array,
            default: [],
        },
        currentId: {
            type: Number,
            default: 0,
        },
        indexSwiper: {
            type: Number,
            default: 0,
        },
    },
    mounted() {
        
    },
    methods: {   
        CorDate(date) {
            const datasort = moment(String(date)).format('DD MMMM')
            return datasort
        },
        DateToday(value) {
            const today = moment(value).format('dddd');
            return today
        },
        SetCurrent(value) {
            this.$emit('active', value)
        },
        Disabled() {
            console.log('disabled')
        }
    },
    setup(props) {
        const swiper = ref(null)
        const onSwiper = (swiper) => {
            setTimeout(() => {
                swiper.slideTo(props.indexSwiper, 500, true);
            }, 510)
        };
        const prev = ref(null);
        const next = ref(null);
      return {
        modules: [Navigation],
        onSwiper,
        prev,
        next,
      };
    },
    created() {
        moment.locale('ru');
    }
  };
</script>