<template>
   <Header />
   <div class="player"
      v-if="Object.keys(tv || {}).length != 0 && playList.length > 0 && showEfirBool && (tv.status == 1 || tv.status == 4)"
   >
      <div class="container">
         <section class="defaultStage" v-if="inEfir">
            <!-- <div v-if="!preloader"> -->
            <live-video 
               :key="componentKey" 
               :teleId="teleId" 
               :videoInfo="videoInfo" 
               :options="videoOptions"
               :curr_time="curr_time" 
               :videoId="videoId"
               :durationInEfir="durationInEfir" 
               :timeOfEfir="tv.time_to_start"
               :timelive="timelive"
            />
            <!--                 </div>
                <div class="loader center-main" v-else>
                     <div class="lds-ring fff_loader"><div></div><div></div><div></div><div></div></div>
                </div>      -->
         </section>
         <section class="defaultStage in_not_efir" :class="{ 'end-active_efir': endOver, 'wait-efir_block': !loaderNew }"
            v-else>
            <div class="posIn-not_efir" v-if="!endOver">
               <span v-if="loaderNew">
                  <div class="loader">
                     <div class="lds-ring fff_loader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                     </div>
                  </div>
               </span>
               <span v-else>
                  <h3 v-if="startVideo">
                     Эфир начнется в {{ CorTime(startVideo) }}
                  </h3>
               </span>
            </div>
            <div class="posIn-not_efir" v-else>
               <h3>Эфир закончился</h3>
            </div>
         </section>
         <div class="player__chat">
            <div class="player__chat_title">
               <p>Чат</p>
            </div>
            <div class="player__chat_wrapper">
               <div class="chat__block" v-if="nickname == ''">
                  <div class="dflex">
                     <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                           d="M14 8C14 11.3137 11.3137 14 8 14C6.81929 14 5.71824 13.659 4.78998 13.07L2 14L2.92999 11.21C2.34104 10.2818 2 9.18071 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
                           stroke="url(#paint0_linear_516_23700)" stroke-width="1.5" stroke-linecap="round"
                           stroke-linejoin="round" />
                        <path d="M10.6665 8H10.6678V8.00133H10.6665V8Z" stroke="#0066C3" stroke-width="2"
                           stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M8 8H8.00133V8.00133H8V8Z" stroke="#0066C3" stroke-width="2" stroke-linecap="round"
                           stroke-linejoin="round" />
                        <path d="M5.3335 8H5.33483V8.00133H5.3335V8Z" stroke="#0066C3" stroke-width="2"
                           stroke-linecap="round" stroke-linejoin="round" />
                        <defs>
                           <linearGradient id="paint0_linear_516_23700" x1="8" y1="-13.75" x2="8" y2="31.1"
                              gradientUnits="userSpaceOnUse">
                              <stop stop-color="#0080F5" />
                              <stop offset="1" stop-color="#003494" />
                           </linearGradient>
                        </defs>
                     </svg>
                     <p>Придумайте никнейм, чтобы участвовать в обсуждении</p>
                  </div>
                  <input type="text" v-model="nicknameObj" placeholder="Введите никнейм">
                  <button class="btn__blue" @click="saveName(nicknameObj)">
                     Отправить
                  </button>
               </div>
               <p v-for="list in messages"><span>{{ list.name }}</span> {{ list.message }}</p>
            </div>
            <form class="player__chat_input" @submit.prevent="SendMessage()">
               <input type="text" v-model="message" placeholder="Отправить сообщение">
               <button class="btn__blue">
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M12.6668 6.5L8.00016 11.1667L3.3335 6.5" stroke="#fff" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
               </button>
            </form>
         </div>
         <live-track 
            :playList="playList"
            :indexlive="indexlive"
            :timeOnEfir="timeOnEfir"
            :durationInEfir="durationInEfir"
            :currentTime="timelive"
         />
      </div>
   </div>

   <div v-if="slideShow.length > 0">
      <HomeSwiper :slideShow="slideShow" />
   </div>
   <div class="language light-theme" v-if="oprosnik.length != 0">
      <div class="container">
         <div class="language__wrapper" v-for="list, index in oprosnik">
            <img src="@/assets/img/petern.svg" alt="" class="language__bg">
            <p class="language__title">{{ list.questions }}</p>
            <div class="language__block">
               <div class="language__label" v-if="list.variant_1">
                  <input type="checkbox" id="otvet_1" :value="list.otvet_1 + 1" v-model="checkedOtvet1">
                  <div class="language__check">
                     <span>
                        <img src="@/assets/img/check.svg" alt="">
                     </span>
                  </div>
                  <label>{{ list.variant_1 }}</label>
               </div>
               <div class="language__label" v-if="list.variant_2">
                  <input type="checkbox" id="otvet_2" :value="list.otvet_2 + 1" v-model="checkedOtvet2">
                  <div class="language__check">
                     <span>
                        <img src="@/assets/img/check.svg" alt="">
                     </span>
                  </div>
                  <label>{{ list.variant_2 }}</label>
               </div>
               <div class="language__label" v-if="list.variant_3">
                  <input type="checkbox" id="otvet_3" :value="list.otvet_3 + 1" v-model="checkedOtvet3">
                  <div class="language__check">
                     <span>
                        <img src="@/assets/img/check.svg" alt="">
                     </span>
                  </div>
                  <label>{{ list.variant_3 }}</label>
               </div>
               <div class="language__label" v-if="list.variant_4">
                  <input type="checkbox" id="otvet_4" :value="list.otvet_4 + 1" v-model="checkedOtvet4">
                  <div class="language__check">
                     <span>
                        <img src="@/assets/img/check.svg" alt="">
                     </span>
                  </div>
                  <label>{{ list.variant_4 }}</label>
               </div>
            </div>
            <button class="language__btn" @click="SendOpros(list)">Проголосовать</button>
         </div>
      </div>
   </div>
   <SwiperCategory />
   <AboutMain />
   <Donate />
</template>

<style lang="scss" scoped>
@import '@/assets/css/Home/main.css';
</style>

<script>
import axios from 'axios'
import moment from 'moment';
import Header from '@/components/Header/Header'
import SwiperCategory from '@/components/Swiper/MainCategory'
import HomeSwiper from '@/components/Swiper/HomeSwiper'
import Donate from '@/components/Donate/Donate'
import MainComponent from '@/components/Video/MainComponent'
import AboutMain from '@/components/About/AboutMain';
import LiveTrack from "@/components/Swiper/LiveTrack";

export default {
   name: 'HomeView',
   data() {
      return {
         tv: {},
         teleId: 0,
         oprosnik: [],
         playList: [],
         inEfir: false,
         checkedOtvet1: false,
         checkedOtvet2: false,
         checkedOtvet3: false,
         checkedOtvet4: false,
         videoOptions: {
            autoplay: true,
            controls: true,
            liveui: true,
            preload: "metdata",
            sources: [
               {
                  src:
                     'https://barsel.media:8088/hls/stream.m3u8',
                  type: 'application/x-mpegURL'
               }
            ],
            controlBar: {
               pictureInPictureToggle: false,
               ProgressControl: false,
            },
            fluid: false,
            language: "ru",
            videoPk: '',
         },
         slideShow: [],
         messages: [],
         nickname: "",
         nicknameObj: "",
         startVideo: "00:00:00",

         message: "",
         timelive: "00:00:00",
         startVideoDef: "00:00:00",
         durationInEfir: 0,
         timeOnEfir: 0,
         componentKey: false,
         videoInfo: {},
         indexlive: 0,

         curr_time: 0,

         endOver: false,

         loaderNew: false,

         preloader: false,

         showEfirBool: false,

         interval: 0,
         inLine: 0,
         videoId: 0,
      }
   },
   beforeCreate() {
      localStorage.removeItem("radio")
      this.$store.commit('removeRadio')
      this.$store.commit('initializeStore')
   },
   mounted() {
      this.nickname = this.$store.state.nickname

      this.TimeLive()

      this.inLine = setInterval(() => { this.TimeLive() }, 2000)
      this.interval = setInterval(() => { this.CheckStatusTv() }, 5000);
   },
   methods: {
      async GetTeleprogramList() {
         await axios
            .get(`/api/v1/get/teleprogram/today/`)
            .then(response => {
               if (String(response.data) == 'none' || (response.data.status != 1 && response.data.status != 4)) clearInterval(this.interval), clearInterval(this.inLine)
               else {
                  this.tv = response.data
                  this.indexlive = response.data.index
                  this.teleId = response.data.id
                  this.AllMessage()
                  this.GetPlayList(response.data.id)
               }
            })
            .catch(error => { console.log(error) })
      },
      async GetPlayList(value, update = false) {
         await axios
            .get(`/api/v1/get/teleprogram/videos/withads/${value}/`)
            .then(response => {
               console.log("asdsadsada: ", response.data);
               this.loaderNew = true

               this.showEfirBool = response.data.show

               setTimeout(() => {
                  this.indexMax = response.data.results.length
                  this.playList = response.data.results

                  console.log(response.data)

                  if (!update) this.VideoNow()
                  else this.VideoNow(true)
                  this.loaderNew = false
                  this.GoLive()
               }, 500)
            })
            .catch(error => { console.log(error) })
      },
      async GetOprosnik() {
         await axios
            .get(`/api/v1/get/oprosnik/`)
            .then(response => {
               this.oprosnik = response.data
            })
            .catch(error => { console.log(error) })
      },
      async GetSlideShow() {
         await axios
            .get(`/api/v1/get/slideshow/main/False/`)
            .then(response => {
               this.slideShow = response.data

               console.log(response.data)
            })
            .catch(error => { console.log(error) })
      },
      async SendOpros(item) {
         let first = item.otvet_1
         let second = item.otvet_2
         let three = item.otvet_3
         let four = item.otvet_4

         if (this.checkedOtvet1) { first += 1 }
         if (this.checkedOtvet2) { second += 1 }
         if (this.checkedOtvet3) { three += 1 }
         if (this.checkedOtvet4) { four += 1 }

         const oprosnikInfo = {
            otvet_1: first,
            otvet_2: second,
            otvet_3: three,
            otvet_4: four,
         }

         await axios
            .put(`/api/v1/put/oprosnik/${item.id}`, oprosnikInfo)
            .then(response => {
               alert('Спасибо за участие')

               this.checkedOtvet1 = false
               this.checkedOtvet2 = false
               this.checkedOtvet3 = false
               this.checkedOtvet4 = false
            })
            .catch(error => { console.log(error) })
      },
      async SendMessage() {
         const BigData = {
            teleprogram_id: this.teleId,
            name: this.nickname,
            message: this.message,
         }

         await axios
            .post(`/api/v1/chat/teleprogram/${this.teleId}/`, BigData)
            .then(response => {
               this.message = ""
               this.AllMessage()
            })
            .catch(error => { console.log(error) })

      },
      async AllMessage() {
         await axios
            .get(`/api/v1/chat/teleprogram/${this.teleId}/`)
            .then(response => {
               this.messages = response.data
            })
            .catch(error => { console.log(error) })

      },
      VideoNow() {
         if (this.indexMax > this.indexlive) {
            this.videoId = this.playList[this.indexlive].id
            this.videoInfo = this.playList[this.indexlive].video
            this.startVideo = this.playList[this.indexlive].time_to_start
            this.startVideoDef = this.playList[this.indexlive].time_to_start
            this.durationInEfir = parseInt(Number(this.playList[this.indexlive].video.duration) + 30)
         }
      },
      saveName(nick) {
         this.$store.commit('setNickname', nick)
         localStorage.setItem("nickname", nick)
         this.$store.commit('initializeStore')

         this.nickname = nick
      },
      async GetNextVideo() {
         console.log(this.indexlive)
         await axios
            .get(`/api/v1/get/teleprogram/next/${this.teleId}/${this.indexlive + 1}/`)
            .then(response => {
               this.tv = response.data
               this.indexlive = response.data.index
               this.curr_time = 0
               this.inLine = 0
               this.timeOnEfir = 0

               if (this.indexMax < this.indexlive || this.indexMax == this.indexlive) {
                  this.endOver = true
                  clearInterval(this.inLine)
               } else {
                  clearInterval(this.inLine)
                  this.AllMessage()
                  this.GetPlayList(response.data.id, true)
               }

            })
            .catch(error => {
               console.log(error)
            })
      },
      async PublishVideo() {
         await axios
            .get(`/api/v1/video/update/status/${this.videoInfo.id}/${0}/`)
            .then(response => { })
            .catch(error => { console.log(error) })
      },
      async TimeLive() {
         await axios
            .get(`/api/v1/get/time-live/`)
            .then(response => {
               this.timelive = moment(response.data.Time, "HH:mm:ss").format("HH:mm:ss")
               this.curr_time = moment.duration(this.timelive).asSeconds() - moment.duration(this.startVideo).asSeconds()
               this.Percent(this.durationInEfir)
               var check = 0
               if (this.durationInEfir != 0 && this.tv.status == 1) {
                  if (this.curr_time >= this.durationInEfir) {
                     check += 1
                     if (check == 1) {
                        this.PublishVideo()
                        this.GetNextVideo()
                        check = 0
                     }
                  }
               }
            })
            .catch(error => { console.log(error) })
      },
      async CheckStatusTv() {
         await axios
            .get(`/api/v1/get/status/teleprogram/run/`)
            .then(response => {
               this.tv.status = response.data
               if (response.data == 4) this.inEfir = false, this.endOver = true
            })
            .catch(error => { console.log(error) })
      },
      CorTime(timeValue) {
         moment.locale('ru');
         const time = moment(timeValue, "HH:mm:ss").format("HH:mm")
         return time
      },
      Percent(duration) {
         this.timeOnEfir = parseInt((100 * this.curr_time) / duration)
      },
      GoTo(redirect) {
         this.$router.push(redirect)
      },
      GoLive() {
         // if (String(this.startVideoDef) != '00:00:00') {
         //    if (this.startVideoDef <= this.timelive) {
         //       console.log(this.startVideoDef, this.timelive)
         //       if (this.indexMax > this.indexlive) {
         //          if (this.tv.status == 1) {
         //             this.inEfir = true
         //          } else {
         //             this.inEfir = false
         //             this.endOver = true
         //          }
         //       } else {
         //          this.inEfir = false
         //       }

         //    }
         // } else {
         //    if (this.indexMax < this.indexlive || this.indexMax == this.indexlive) {
         //       this.endOver = true
         //    }
         // }
         this.inEfir = true
      },
   },
   components: {
      Header,
      SwiperCategory,
      HomeSwiper,
      Donate,
      'live-video': MainComponent,
      AboutMain,
      LiveTrack,
   },
   created() {
      this.GetTeleprogramList()
      this.GetOprosnik()
      this.GetSlideShow()
   },
   watch: {

   },
}
</script>
