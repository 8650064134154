<template>
  <Header />

    <section v-if="video_info.youtubeIframe">
        <YoutubeFrame :video_info="video_info" :key="video_info" />
    </section>
    <section v-else>
        <VideoComponent 
          :video_info="video_info" 
          :options="videoOptions" 
          :key="componentKey" 
          @updateLink="videoOptions.sources = $event"
        />
    </section>
    <section class="details">
        <div class="container">
            <h2>{{ video_info.name }}</h2>
            <div class="details__wrapper">
                <p class="details__bold">Описание</p>
                <p class="details__text">{{ video_info.description }}</p>    
                <div class="details__tab" :class="{'active': activated}" v-if="video_info.sujet">
                    <p class="details__bold">Сюжет</p>
                    <p class="details__text">{{ video_info.sujet }}</p>
                </div>
                <p class="details__btn" v-if="!activated && video_info.sujet" @click="Toggle()">
                    <span>Читать полностью</span>
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.6668 6.5L8.00016 11.1667L3.3335 6.5" stroke="var(--blue-white)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </p>
                <p class="details__btn" v-else @click="Toggle()" v-show="video_info.sujet">
                    <span>Свернуть</span>
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.6668 6.5L8.00016 11.1667L3.3335 6.5" stroke="var(--blue-white)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                </p>
            </div>
        </div>
    </section>
    <RelatedVideos :pk="categoryPk" :key="categoryPk" />
</template>

<style scoped>
.details {
    padding: 26px 0px 0px;
}

@media (max-width: 576px) {
  .player-video_component {
    min-height: 200px;
  }
}
</style>

<script>
import axios from 'axios'
import Header from '@/components/Header/Header'
import VideoComponent from '@/components/Video/VideoComponent'
import YoutubeFrame from '@/components/Video/YoutubeFrame'
import RelatedVideos from '@/components/Swiper/RelatedVideos'

export default {
  name: 'VideoDetail',
  data() {
    return {  
        activated: false,
        videoOptions: {
            autoplay: true,
            controls: true,
            preload: "metdata",
            sources: [
              {
                src: '',
                type: 'video/mp4'
              }
            ],
            controlBar: {
              pictureInPictureToggle: false,
              ProgressControl: false,
            },
            fluid: false,
            language: "ru",
            videoPk: '',
        },
        video_info: {},
        categoryPk: 1,

        componentKey: true,
    }
  },
  beforeCreate() {
      localStorage.removeItem("radio")
      this.$store.commit('removeRadio')
      this.$store.commit('initializeStore')
  },
  methods: {
      goTo(redirect) {
        this.$router.push(redirect)
      },
      async forceRerender() {
        this.componentKey = false; 

        await this.$nextTick();

        this.componentKey = true;  
      },
      Toggle() {
        this.activated = !this.activated
      },
      async GetVideoDetail() {
          this.videoPk = this.$route.params.pk

          await axios
          .get(`/api/v1/get/video/${this.videoPk}/`)
          .then(response => {
              this.video_info = response.data
              // if(response.data.draft == 1) this.$router.push('/')
              if (response.data.category_id != null || category_id != '') {
                this.categoryPk = response.data.category_id
                console.log(response.data.category_id)
              } else {
                this.categoryPk = 1
              }
              this.videoOptions.sources[0].src = response.data.link_video
              console.log(response.data)

          })
          .catch(error => {
            console.log(error)
          })           
       },         
  },
  components: {
    Header,
    VideoComponent,
    RelatedVideos,
    YoutubeFrame
  },
  created() {
    this.GetVideoDetail()
  },
  watch: {
    "$route.params.pk"(value) {
      this.GetVideoDetail()
      this.forceRerender()
    },    
    videoOptions(value) {
      console.log(value)
      this.forceRerender()
    },
  },
}
</script>
