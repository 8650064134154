<template>
  <Header />
    <section class="about">
        <div class="container">
            <h1>Барс-Эль Медиа</h1>
            <p class="about__subtitle">Карачаево-балкарское интернет-телевидение и радио. Проект, созданный Межрегиональной общественной организацией «Барс-Эль» и фондом «Эльбрусоид».</p>
            <div class="about__wrapper">
                <div class="about__sidebar">
                    <ul>
                        <li :class="{'active': currentVal == 'idea'}"><a @click="GoToRef('idea')">{{ about.title1 }}</a></li>
                        <li :class="{'active': currentVal == 'mis'}"><a @click="GoToRef('mis')">{{ about.title2 }}</a></li>
                        <li :class="{'active': currentVal == 'them'}"><a @click="GoToRef('them')">{{ about.title3 }}</a></li>
                        <li :class="{'active': currentVal == 'coop'}"><a @click="GoToRef('coop')">{{ about.title4 }}</a></li>
                        <li :class="{'active': currentVal == 'contact'}"><a @click="GoToRef('contact')">{{ about.title5 }}</a></li>
                    </ul>
                </div>
                <div class="about__block">
                    <div class="" ref="idea">
                        <h2>{{ about.title1 }}</h2>
                        <p class="about__text">{{ about.disc1 }}</p>
                    </div>
                    <div ref="mis">
                        <h2>{{ about.title2 }}</h2>
                        <p class="about__text">{{ about.disc2 }}</p>
                    </div>
                    <div ref="them">
                        <h2>{{ about.title3 }}</h2>
                        <p class="about__text">{{ about.disc3 }}</p>
                    </div>
                    <div ref="coop">
                        <h2>{{ about.title4 }}</h2>
                        <p class="about__text">{{ about.disc4 }}</p>
                    </div>
                    <div class="" ref="contact">
                        <h2>{{ about.title5 }}</h2>
                        <div class="about__contact">
                            <div class="about__item">
                                <p class="about__bold">г. Москва, Семеновская пл., 7</p>
                                <div class="about__grey">
                                    <a href="mailto: barselmedia@mail.ru">barselmedia@mail.ru</a>
                                    <!-- <a href="tel: +7 (495) 366-23-54">+7 (495) 366-23-54 / 366-39-60</a> -->
                                </div>
                            </div>
<!--                             <div class="about__item">
                                <p class="about__bold">г. Карачаевск, ул. Ленина, 34а</p>
                                <div class="about__grey">
                                    <a href="mailto: fond09@elbrusoid.org">fond09@elbrusoid.org</a>
                                    <a href="tel: +7 928 923-96-66">+7 928 923-96-66</a>
                                </div>
                            </div>
                            <div class="about__item">
                                <p class="about__bold">г. Нальчик, ул. Ахохова, 92</p>
                                <div class="about__grey">
                                    <a href="mailto: fond09@elbrusoid.org">fond07@elbrusoid.org</a>
                                    <a href="tel: +7 (866-2) 77-59-12">+7 (866-2) 77-59-12</a>
                                </div>
                            </div>
                            <div class="about__item">
                                <p class="about__bold">г. Ставрополь, ул. Мира, 212</p>
                                <div class="about__grey">
                                    <a href="mailto: elbrusoid.stavropol@yandex.ru">elbrusoid.stavropol@yandex.ru</a>
                                    <a href="tel: +7 (865) 474-088">+7 (865) 474-088</a>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="donate">
<!--         <div class="container">
            <div class="donate__wrapper">
                <h1>Поддержите Фонд</h1>
                <p class="donate__subtitle">Мы будем благодарны за помощь нашему проекту — ежемесячную или разовую</p>
                <div class="donate__check_wrapper">
                    <div class="donate__check">
                        <span class="active">Ежемесячная</span>
                        <span>Разовая</span>
                    </div>
                </div>
                <div class="donate__form">
                    <input type="text" placeholder="Ваше имя" class="donate__input">
                    <input type="text" placeholder="Email" class="donate__input">
                    <input type="text" placeholder="Сумма ежемесячного пожертвования" class="donate__input">
                    <div class="donate__row">
                        <a #">200 ₽</a>
                        <a #">500 ₽</a>
                        <a #">1000 ₽</a>
                        <a #">5000 ₽</a>
                    </div>
                    <div class="donate__label">
                        <input type="checkbox">
                        <div></div>
                        <p>Я согласен(а) с <a #">Политикой конфиденциальности</a></p>
                    </div>
                    <button class="donate__btn">Поддержать</button>
                </div>
            </div>
        </div> -->
    </section>
</template>

<style type="text/css">

</style>

<script>
import axios from 'axios'
import Header from '@/components/Header/Header'

export default {
  name: 'About',
  data() {
    return {  
        about: {},
        currentVal: "idea",
    }
  },
  methods: {
      goTo(redirect) {
        this.$router.push(redirect)
      },
      GoToRef(val) {
        this.currentVal = val
        this.$refs[val].scrollIntoView({ behavior: 'smooth' });
      },
      async GetAbout() {
          await axios
          .get(`/api/v1/get/about/`)
          .then(response => {
              this.about = response.data[0]

              console.log(response.data[0])
          })
          .catch(error => {
            console.log(error)
          })           
      }, 
  },
  components: {
    Header
  },
  created() {
    this.GetAbout()
  },
}
</script>
