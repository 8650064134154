<template>
    <div>
        <div class="">
            <swiper
              :modules="modules"
              :breakpoints="swiperOptions.breakpoints"
              class="radio-program__slider"
              :navigation="{
                  prevEl: prev,
                  nextEl: next,
              }"
              @swiper="onSwiper"
            >
                <div ref="prev" class="slick-prev slick-arrow">prev</div>
                    <swiper-slide
                        v-for="list, index in radioProgram"
                        class="radio-program__slider_item"
                        :class="{
                            'active': current_programm == list.id, 
                            'radio-program__slider_dis': 3 > index
                        }"
                        @click="SetCurrent(list)"

                    >
                        <p class="radio-program__slider_day">{{ DateToday(list.date_to_start) }}</p>
                        <p class="radio-program__slider_date">{{ CorDate(list.date_to_start) }}</p>
                    </swiper-slide>
                <div ref="next" class="slick-next slick-arrow">next</div>
            </swiper>
        </div>
    </div>
</template>
<style>

</style>
<script>
  import { ref } from 'vue';
  import axios from 'axios'
  import moment from 'moment';
  import SwiperCore, { Navigation, Autoplay } from "swiper";
  import { Swiper, SwiperSlide } from 'swiper/vue';
  SwiperCore.use(Autoplay);
  import 'swiper/css';
  import 'swiper/css/navigation';

  export default {
    name: 'RadiosNavigation',
    data() {
      return {
        autoplay: {
          delay: 5000,
        },
        swiperOptions: {
            breakpoints: {       
                700: {       
                   slidesPerView: 7,
                   spaceBetween: 15    
                },                                        
            }   
       },
       cat_lists: [],
      }
    },
    components: {
      Swiper,
      SwiperSlide,
    },
    props: {
        radioProgram: {
            type: Array,
            default: [],
        },
        current_programm: {
            type: Number,
            default: 0,
        },
        indexSwiper: {
            type: Number,
            default: 0,
        },
    },
    methods: {   
        CorDate(date) {
            const datasort = moment(String(date)).format('DD MMMM')
            return datasort
        },
        DateToday(value) {
            const today = moment(value).format('dddd');
            return today
        },
        SetCurrent(value) {
            this.$emit('active', value)
        },
        Disabled() {
            console.log('disabled')
        }
    },
    setup() {
        const prev = ref(null);
        const next = ref(null);
        const onSwiper = (swiper) => {
            // setTimeout(() => {
            //     swiper.slideTo(props.indexSwiper, 500, true);
            // }, 510)
        };
      return {
        modules: [Navigation],
        onSwiper,
        prev,
        next,
      };
    },
    created() {
        moment.locale('ru');
    }    
  };
</script>