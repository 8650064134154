<template>
   <Header />
   <section class="search">
      <div class="container">
         <h1>Результаты поиска</h1>
         <div class="category__row_wrapper">
            <div class="category__row">
               <a @click="GoScroll('video')">Видео</a>
               <a @click="GoScroll('radio')">Радио</a>
            </div>
         </div>
         <div class="search__block">
            <div ref="video">
               <div class="search__title">
                  <h2>Видео</h2>
                  <router-link to="/catalog" class="search__more">
                     <p>Все видео</p>
                     <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.6668 6.5L8.00016 11.1667L3.3335 6.5" stroke="#262F44" stroke-width="2"
                           stroke-linecap="round" stroke-linejoin="round" />
                     </svg>
                  </router-link>
               </div>
               <div class="category__tv">
                  <router-link v-for="item in videos" :to="'/video/item/' + item.id" class="category__tv_item">
                     <img :src="item.get_preview" v-if="item.get_preview">
                     <img src="@/assets/img/Cover_video2.jpg" v-else>
                     <div class="list-video__name">
                        <p>{{ item.name }}</p>
                     </div>
                     <div class="list-film__watch">
                        <p>{{ item.prosmotri }}</p>
                     </div>
                     <div class="list-film__date">
                        <p>{{ CorDate(item.date_added) }}</p>
                     </div>
                  </router-link>
               </div>
            </div>
            <div ref="radio">
               <div class="search__title">
                  <h2>Радио</h2>
                  <router-link to="/radio/catalog" class="search__more">
                     <p>Все аудиофайлы</p>
                     <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.6668 6.5L8.00016 11.1667L3.3335 6.5" stroke="#262F44" stroke-width="2"
                           stroke-linecap="round" stroke-linejoin="round" />
                     </svg>
                  </router-link>
               </div>
               <div class="category__radio over__hide">
                  <a @click="SetRadio(item)" v-for="item in radios" class="music-slider__item margin-zero">
                     <div class="music-slider__prev">
                        <img :src="item.get_preview" v-if="item.get_preview">
                        <img src="@/assets/img/music_slider.jpg" v-else>
                        <div class="list-film__watch">
                           <p>{{ item.prosmotri }}</p>
                        </div>
                        <div class="list-film__date">
                           <p>{{ CorDate(item.date_added) }}</p>
                        </div>
                     </div>
                     <div class="music-slider__item_info">
                        <p class="music-slider__track">{{ item.name }}</p>
                        <p class="music-slider__name" v-if="item.author">{{ item.author.name }}</p>
                     </div>
                  </a>
               </div>
            </div>
         </div>
      </div>
   </section>
</template>

<style type="text/css"></style>

<script>
import axios from 'axios'
import Header from '@/components/Header/Header'
import moment from 'moment';

export default {
   name: 'Search',
   data() {
      return {
         querySearch: "",
         videos: [],
         radios: [],
      }
   },
   methods: {
      goTo(redirect) {
         this.$router.push(redirect)
      },
      GoScroll(value) {
         if (value == 'video') this.$refs.video.scrollIntoView({ behavior: 'smooth' });
         if (value == 'radio') this.$refs.radio.scrollIntoView({ behavior: 'smooth' });
      },
      async GetSearchVideos() {
         this.querySearch = this.$route.query.query

         await axios
            .get(`/api/v1/search/videos/${this.querySearch}/`)
            .then(response => {
               this.videos = response.data
               console.log(response.data)
            })
            .catch(error => {
               console.log(error)
            })
      },
      async GetSearchRadios() {
         this.querySearch = this.$route.query.query

         await axios
            .get(`/api/v1/search/radios/${this.querySearch}/`)
            .then(response => {
               this.radios = response.data
               console.log(response.data)
            })
            .catch(error => {
               console.log(error)
            })
      },
      async HistoryAddRadio(idc) {
         await axios
            .get(`/api/v1/add/radio/history/${idc}/`)
            .then(response => {
               console.log('history add')
            })
            .catch(error => {
               console.log(error)
            })
      },
      SetRadio(value) {
         const radio = JSON.stringify(value)

         this.$store.commit('setRadio', radio)
         localStorage.setItem("radio", radio)

         this.HistoryAddRadio(value.id)
         this.$store.commit('initializeStore')
      },
      CorDate(date) {
         moment.locale('ru');
         const datasort = moment(String(date)).format('DD MMMM YYYY')
         return datasort
      },
   },
   components: {
      Header
   },
   mounted() {
      this.GetSearchVideos()
      this.GetSearchRadios()
   },
   watch: {
      '$route.params'() {
         this.GetSearchVideos()
         this.GetSearchRadios()
      },
   },
}
</script>
