<template>
    <div class="music-slider" v-for="list, index in radiosArray">
        <div class="container">
            <h2>{{ list.name }}</h2>
            <swiper 
                :modules="modules"
                :navigation="{
                    prevEl: prev,
                    nextEl: next,
                }"
                :breakpoints="swiperOptions.breakpoints"
                class="music-slider__wrapper" 
            >
                <div ref="prev" class="slick-prev slick-arrow">prev</div>
                    <swiper-slide v-for="item in list.radios" class="music-slider__item" @click="SetRadio(item)">
                        <div class="music-slider__prev">
                            <img :src="item.get_preview" v-if="item.get_preview">
                            <img src="@/assets/img/Cover_radio.jpg" v-else>
                            <div class="list-film__watch">
                                <p>{{ item.prosmotri }}</p>
                            </div>
                            <div class="list-film__date">
                                <p>{{ CorDate(item.date_added) }}</p>
                            </div>
                        </div>
                        <div class="music-slider__item_info">
                            <p class="music-slider__track">{{ item.name }}</p>
                            <p class="music-slider__name" v-if="item.author">{{ item.author.name }}</p>
                        </div>
                    </swiper-slide>
                <div ref="next" class="slick-next slick-arrow">next</div>
            </swiper>
        </div>
    </div>
</template>
<style>
    .music-slider .swiper {
        overflow: visible;
    }

    .music-slider__item img {
        border-radius: 18px;
    }
</style>
<script>
  import { ref } from 'vue';
  import axios from 'axios'
  import moment from 'moment';
  import SwiperCore, { Navigation, Autoplay } from "swiper";
  import { Swiper, SwiperSlide } from 'swiper/vue';
  SwiperCore.use(Autoplay);
  import 'swiper/css';
  import 'swiper/css/navigation';

  export default {
    name: 'CatalogRadios',
    data() {
      return {
        autoplay: {
          delay: 5000,
        },
        swiperOptions: {
          breakpoints: {       
            1024: {       
               slidesPerView: 5,
               spaceBetween: 35    
            },                                        
            576: {       
               slidesPerView: 2,
               spaceBetween: 35    
            },   
          }
       },
       cat_lists: [],
      }
    },
    components: {
      Swiper,
      SwiperSlide,
    },
    props: {
      radiosArray: {
        type: Array,
        default: []
      }
    },
    methods: {
        CorDate(date) {
              moment.locale('ru');
              const datasort = moment(String(date)).format('DD MMMM YYYY')
              return datasort
        },
        async HistoryAddRadio(idc) {
              await axios
              .get(`/api/v1/add/radio/history/${idc}/`)
              .then(response => {
                  console.log('history add')
              })
              .catch(error => {
                console.log(error)
              })           
        },   
        close () {
          localStorage.removeItem("radio")
          this.$store.commit('removeRadio')
          this.$store.commit('initializeStore')
        }, 
        SetRadio(value) {
            const radio = JSON.stringify(value)

            this.$store.commit('setRadio', radio)
            localStorage.setItem("radio", radio)

            this.HistoryAddRadio(value.id)
            this.$store.commit('initializeStore')
        },
    },
    setup() {
       const prev = ref(null);
       const next = ref(null);
      return {
        modules: [Navigation],
        prev,
        next,
      };
    },
    created() {

    }
  };
</script>