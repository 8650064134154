<template>
    <swiper 
        :modules="modules"
        :initial-slide="indexLive"
        navigation
        mousewheel
        class="player__sidebar slider-film slid_playlist"
        :slides-per-group="1"
        :slides-per-view="1"
        :slides-offset-before="-4"
        :slides-offset-after="0"
        :space-between="0"
        :breakpoints="breakpoint"
    >
        <swiper-slide 
            class="player__sidebar_item" 
            v-for="(list, index) in playlist"
            :class="{'active': index === indexLive}"
        >
            <div class="player__sidebar_item_inner">
                <p class="player__sidebar_time">
                    <span>{{ CorTime(list.time_to_start) }}</span>
                    <span class="player__sidebar_name_age" v-if="list.video.censored != 99">
                        {{ list.video.censored }}+
                    </span>
                </p>
                <div class="player__sidebar_progres">
                    <span v-if="index === indexLive && durationLive !== 0" :style="{'width': timeLive + '%'}"></span>
                </div>
                <div class="player__sidebar_name">
                    <p>{{ list.video.name }}</p>
                </div>
            </div>
        </swiper-slide>
    </swiper>
</template>

<style lang="scss" scoped>
.player__sidebar_time {
    position: relative;
}

.player__sidebar_name {
    display: flex;
    position: relative;

    &_age {
        position: absolute;
        top: 6px;
        right: 0;
        color: #82909f;
        font-family: "Inter", sans-serif;
        font-size: 13px;
        font-weight: 600;
        line-height: 120%;
        border-radius: 28px;
        background: #edf0f3;
        padding: 2px 7px;
    }
}
</style>

<script lang="js">
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Mousewheel } from "swiper";
import { defineComponent, toRefs, computed } from "vue";

const CorTime = (timeValue) => {
    moment.locale("ru");
    const time = moment(timeValue, "HH:mm:ss").format("HH:mm");

    return time;
}

const breakpoint = {
    640: {
        direction: "horizontal",
        slidesPerView: "auto",
        slidesPerGroup: 1,
        spaceBetween: 0,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 16,
        centeredSlides: false,
    },
    1194: {
        direction: "horizontal",
        slidesPerView: "auto",
        slidesPerGroup: 1,
        spaceBetween: 0,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 16,
        centeredSlides: false,
    },
    1280: {
        direction: "horizontal",
        slidesPerView: "auto",
        slidesPerGroup: 1,
        spaceBetween: 0,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 16,
        centeredSlides: false,
    },
    1366: {
        direction: "horizontal",
        spaceBetween: 0,
        slidesPerView: "auto",
        slidesPerGroup: 1,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 16,
    },
    1920: {
        direction: "horizontal",
        spaceBetween: 0,
        slidesPerView: "auto",
        slidesPerGroup: 1,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 16,
    },
    2560: {
        direction: "horizontal",
        spaceBetween: 0,
        slidesPerView: "auto",
        slidesPerGroup: 1,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 16,
    },
};

export default defineComponent({
    name: "LiveTrack",
    components: {Swiper, SwiperSlide},
    props: {
        playList: {
            type: Array,
            required: true,
        },
        indexlive: {
            type: Number,
            required: true,
        },
        durationInEfir: {
            type: Number,
            required: true,
        },
        timeOnEfir: {
            type: Number,
            required: true,
        },
        currentTime: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const { currentTime, durationInEfir: durationLive, playList } = toRefs(props);

        const playlist = computed(() => {
            return playList.value.filter((item) => item.time_to_start !== "" && item.video.draft !== 2);
        });

        const indexLive = computed(() => {
            const time = moment.duration(currentTime.value).asSeconds();

            const index = playlist.value.findIndex((item) => time <= moment.duration(item.time_to_start).asSeconds()) - 1;
        
            return Math.max(0, index);
        });

        const timeLive = computed(() => {
            if (indexLive.value === -1) {
                return 0;
            }

            const time = moment.duration(currentTime.value).asSeconds();
            const timeToStart = moment.duration(playlist.value[indexLive.value].time_to_start).asSeconds();
            const timeToEnd = Number(playlist.value[indexLive.value].video.duration) + 30;

            return ((time - timeToStart) / timeToEnd) * 100;
        });

        return {
            modules: [Navigation, Mousewheel],
            CorTime,
            breakpoint,
            durationLive,
            playlist,
            indexLive,
            timeLive,
            currentTime,
        };
    }
});
</script>