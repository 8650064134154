<template>
    <div class="list-film list-film__padding">
        <div class="container">
            <h2>Похожие видео</h2>
            <swiper 
                :modules="modules"
                :navigation="{
                    prevEl: prev,
                    nextEl: next,
                }"
                :autoplay="autoplay"
                :breakpoints="swiperOptions.breakpoints"
                @swiper="onSwiper"
                @slideChange="onSlideChange"
                class="list-film__wrapper" 
            >
                <div ref="prev" class="slick-prev slick-arrow">prev</div>
                    <swiper-slide v-for="item in videos">
                        <router-link :to="'/video/item/' + item.id" class="list-film__slide">
                            <img :src="item.get_previewCatalog" v-if="item.get_previewCatalog">
                            <img src="@/assets/img/Cover_video2.jpg" v-else>
                            <div class="list-video__name">
                                <p>{{ item.name }}</p>
                            </div>
                            <div class="list-film__watch">
                                <p>{{ item.prosmotri }}</p>
                            </div>
                            <div class="list-film__date">
                                <p>{{ CorDate(item.date_added) }}</p>
                            </div>
                            <div class="list-z__bottom"></div>
                        </router-link>
                    </swiper-slide>
                <div ref="next" class="slick-next slick-arrow">next</div>
            </swiper>
        </div>
    </div>
</template>
<style>
    .list-film__slide {
        display: block;
    }
</style>
<script>
  import { ref } from 'vue';
  import axios from 'axios'
  import moment from 'moment';
  import SwiperCore, { Navigation, Autoplay } from "swiper";
  import { Swiper, SwiperSlide } from 'swiper/vue';
  SwiperCore.use(Autoplay);
  import 'swiper/css';
  import 'swiper/css/navigation';

  export default {
    name: 'RelatedVideos',
    data() {
      return {
        autoplay: {
          delay: 5000,
        },
        swiperOptions: {
          breakpoints: {       
            700: {       
               slidesPerView: 5,
               spaceBetween: 35    
            },                                        
          }
       },
       cat_lists: {},
       videos: [],
      }
    },
    components: {
      Swiper,
      SwiperSlide,
    },
    props: {
      pk: {
        type: Number,
        default: 2,
      }
    },
    methods: {
        GoTo(redirect, item) {
            this.$router.push(redirect + item)
        },
        async GetCategoryList(pk) {
          await axios
          .get(`/api/v1/get/related/list/${pk}/`)
          .then(response => {
              this.videos = response.data
              console.log(response.data)
          })
          .catch(error => {
            console.log(error)
          })           
        },     
        CorDate(date) {
              moment.locale('ru');
              const datasort = moment(String(date)).format('DD MMMM YYYY')
              return datasort
        },
    },
    setup() {
       const prev = ref(null);
       const next = ref(null);
      return {
        modules: [Navigation],
        prev,
        next,
      };
    },
    mounted() {
        setTimeout(() => {
            console.log(this.pk)
            this.GetCategoryList(this.pk)
        }, 800)
    }
  };
</script>