<template>
   <section class="popular">
      <div class="container">
         <div class="popular__block">
            <div class="popular__title">
               <h2>Популярные радиопередачи</h2>
               <!--                     <a href="#" class="popular__more">
                        <span>Смотреть все</span>
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.6668 6.5L8.00016 11.1667L3.3335 6.5" stroke="var(--nav-green)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </a> -->
            </div>
            <div class="popular__wrapper">
               <div class="popular__item" v-for="list in radioPeredachi" @click="SetRadio(list)">
                  <div class="popular__prev">
                     <img :src="list.get_preview" v-if="list.get_preview">
                     <img src="@/assets/img/Cover_radio.jpg" v-else>
                     <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                           d="M4.36077 1.27343C3.36667 0.57687 2 1.28802 2 2.50188V9.49827C2 10.7121 3.36668 11.4233 4.36078 10.7267L9.36486 7.22028C10.2115 6.62704 10.2115 5.373 9.36484 4.77977L4.36077 1.27343Z"
                           fill="#fff" />
                     </svg>
                  </div>
                  <div class="popular__info">
                     <p class="popular__track">{{ list.name }}</p>
                     <p class="popular__name" v-if="list.author">{{ list.author.name }}</p>
                  </div>
                  <p class="popular__time" v-if="list.duration">{{ formatds(list.duration) }}</p>
                  <div class="popular__overflow">
                     <div class="popular__func">
                        <!-- <a @click="downloadFile(list.link_radio)">
                           <svg width="24" height="24" viewBox="0 0 24 24" fill="var(--black)"
                              xmlns="http://www.w3.org/2000/svg">
                              <path d="M6 21H18" stroke="var(--black)" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round" />
                              <path d="M12 3L12 17" stroke="var(--black)" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round" />
                              <path d="M17 12L12 17L7 12" stroke="var(--black)" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round" />
                           </svg>
                        </a> -->
                        <a :href="list.link_radio" download>
                           <svg width="24" height="24" viewBox="0 0 24 24" fill="var(--black)"
                              xmlns="http://www.w3.org/2000/svg">
                              <path d="M6 21H18" stroke="var(--black)" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round" />
                              <path d="M12 3L12 17" stroke="var(--black)" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round" />
                              <path d="M17 12L12 17L7 12" stroke="var(--black)" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round" />
                           </svg>
                        </a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="popular__block">
            <div class="popular__title">
               <h2>Популярные песни</h2>
               <!--                     <a href="#" class="popular__more">
                        <span>Смотреть все</span>
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.6668 6.5L8.00016 11.1667L3.3335 6.5" stroke="var(--nav-green)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </a> -->
            </div>
            <div class="popular__wrapper">
               <div class="popular__item" v-for="list in radioPesnya" @click="SetRadio(list)">
                  <div class="popular__prev">
                     <img :src="list.get_preview" v-if="list.get_preview">
                     <img src="@/assets/img/Cover_radio.jpg" v-else>
                     <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                           d="M4.36077 1.27343C3.36667 0.57687 2 1.28802 2 2.50188V9.49827C2 10.7121 3.36668 11.4233 4.36078 10.7267L9.36486 7.22028C10.2115 6.62704 10.2115 5.373 9.36484 4.77977L4.36077 1.27343Z"
                           fill="#fff" />
                     </svg>
                  </div>
                  <div class="popular__info">
                     <p class="popular__track">{{ list.name }}</p>
                     <p class="popular__name" v-if="list.author">{{ list.author.name }}</p>
                  </div>
                  <p class="popular__time" v-if="list.duration">{{ formatds(list.duration) }}</p>
                  <div class="popular__overflow">
                     <div class="popular__func">
                        <!-- <a @click="downloadFile(list.link_radio)">
                           <svg width="24" height="24" viewBox="0 0 24 24" fill="var(--black)"
                              xmlns="http://www.w3.org/2000/svg">
                              <path d="M6 21H18" stroke="var(--black)" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round" />
                              <path d="M12 3L12 17" stroke="var(--black)" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round" />
                              <path d="M17 12L12 17L7 12" stroke="var(--black)" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round" />
                           </svg>
                        </a> -->
                        <a :href='list.link_radio' download>
                           <svg width="24" height="24" viewBox="0 0 24 24" fill="var(--black)"
                              xmlns="http://www.w3.org/2000/svg">
                              <path d="M6 21H18" stroke="var(--black)" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round" />
                              <path d="M12 3L12 17" stroke="var(--black)" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round" />
                              <path d="M17 12L12 17L7 12" stroke="var(--black)" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round" />
                           </svg>
                        </a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
</template>
<style lang="scss"></style>
<script>
import axios from 'axios'
import moment from 'moment';
// import 'moment-duration-format';
import videojs from 'video.js';


export default {
   name: 'PopularList',
   data() {
      return {
         player: null,
         radioPeredachi: [],
         radioPesnya: [],
      }
   },
   components: {

   },
   props: {

   },
   mounted() {
      // console.log(this.formatds(187.421))
   },
   beforeDestroy() {

   },
   methods: {
      async GetRadioPeredachi() {
         await axios
            .get(`/api/v1/get/radio/type/1/`)
            .then(response => {
               this.radioPeredachi = response.data
               console.log(this.radioPeredachi)
            })
            .catch(error => {
               console.log(error)
            })
      },
      async GetRadioPesnya() {
         await axios
            .get(`/api/v1/get/radio/type/2/`)
            .then(response => {
               this.radioPesnya = response.data
               console.log(this.radioPesnya)
            })
            .catch(error => {
               console.log(error)
            })
      },
      downloadFile(file) {
         window.open(file, 'download')
      },
      formatds(seconds) {
         this.checkMoment(seconds)
         seconds = parseInt(seconds)
         let h = Math.floor(moment.duration(seconds, 'seconds').asHours())
         let m = Math.floor(moment.duration(seconds, 'seconds').minutes())
         let s = Math.floor(moment.duration(seconds, 'seconds').seconds())
         return this.checkMoment(h) + ':' + this.checkMoment(m) + ':' + this.checkMoment(s)
      },
      checkMoment(value) {
         if (value == 0) {
            return '00'
         } else if (value < 10) {
            return '0' + value
         } else {
            return value
         }
      },
      SetRadio(value) {
         const radio = JSON.stringify(value)

         this.$store.commit('setRadio', radio)
         localStorage.setItem("radio", radio)

         this.$store.commit('initializeStore')
      },
   },
   created() {
      moment.locale('ru');

      this.GetRadioPeredachi()
      this.GetRadioPesnya()
   }
};
</script>