<template>
   <div class="wrapper" :class="{ 'dark-theme': theme, 'light-theme': !theme }">
      <router-view />
      <Footer />
      <vue-progress-bar />

      <radio-player v-if="Object.keys(radioInfo || {}).length != 0" :radio="radioInfo" :autoPlay="true"
         :file="radioInfo.link_radio" downloadable>
      </radio-player>
   </div>
</template>


<style>
@import '@/assets/css/reset.css';
@import '@/assets/fonts/fonts.css';
@import '@/assets/css/slick.css';
@import '@/assets/css/style.css';
@import '@/assets/css/variable.css';
</style>

<script>
// import '@/assets/js/app.js';

import { nextTick, ref } from 'vue';

import RadioPlayer from '@/components/Radio/RadioPlayer'
import Footer from '@/components/Footer/Footer'
import axios from 'axios'

export default {
   data() {
      return {
         theme: false,
         file: '',
         componentKey: true,
         radioInfo: {},
      }
   },
   components: {
      Footer,
      'radio-player': RadioPlayer,
   },
   beforeCreate() {
      // localStorage.removeItem("radio")
      // this.$store.commit('removeRadio')

      // this.$store.commit('initializeStore')

      // const token = this.$store.state.token

      // if (token) {
      //   axios.defaults.headers.common['Authorization'] = "Token " + token
      // } else {
      //   axios.defaults.headers.common['Authorization'] = ""
      // }
   },
   mounted() {
      this.$Progress.finish();

      document.title = 'Barsel El Media'

      this.theme = Boolean(this.$store.state.theme)

      this.radioInfo = this.$store.state.radio
   },
   created() {
      this.$Progress.start();

      this.$router.beforeEach((to, from, next) => {

         if (to.meta.progress !== undefined) {
            let meta = to.meta.progress;

            this.$Progress.parseMeta(meta);
         }

         this.$Progress.start();

         next();
      });

      this.$router.afterEach((to, from) => {
         this.$Progress.finish();
      });
   },
   methods: {
      async forceRerender() {
         this.componentKey = false;

         await this.$nextTick();

         this.componentKey = true;
      },
   },
   computed: {

   },
   watch: {
      "$store.state.theme"(value) {
         this.theme = Boolean(this.$store.state.theme)
      },
      "$store.state.radio"(value) {
         this.radioInfo = value
         console.log(value)
      }
   }
}
</script>